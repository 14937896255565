// import React from 'react';

// import { Layout, ContentBlock } from '@better-place/better-ui';

// const NotFoundPage = () => (
//   <Layout>
//     <ContentBlock>
//       <h1>404 Page Not Found</h1>
//       <p>The page you were looking for could not be found.</p>
//     </ContentBlock>
//   </Layout>
// );

// export default NotFoundPage;
import React from "react";
import "../styles/main.scss";
import { Seo } from '@better-place/better-ui';

function NoContent() {
  return (
    <div className="App">
      <Seo siteTitle="Page not found" title="Better Pride" description="Page not found" />
      <div className="text-center my-5 mt-sm-0">
        <h1 className="my-3 mt-5">404 Page Not Found</h1>
        <p>The page you were looking for could not be found.</p>
      </div>
    </div>
  );
}

export default NoContent;
