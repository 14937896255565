import PropTypes from 'prop-types';
import React from 'react';

import { Seo, Breadcrumb } from '@better-place/better-ui';
import TextBlock from '@better-place/better-ui/components/TextBlock';
import BetterPrideLogo from '../../images/better-pride-logo.png';
import './styles.scss';

const Header = ({
  title, siteTitle, content, children,
}) => (
  <header className="content-header">

    <div className="content-header__inner">
      {title && <Breadcrumb title={title} />}

      <div className="content-header__column-1">
        <TextBlock>
          {children.length ? (
            children
          ) : (
            <>
              <h1>{title || siteTitle}</h1>
              <p>{content}</p>
            </>
          )}
        </TextBlock>
      </div>
      <div className="content-header__column-2">
        <img src={BetterPrideLogo} alt="Better Pride logo" />
      </div>
    </div>
  </header>
);

Header.propTypes = {
  children: PropTypes.node,
  title: PropTypes.string,
  siteTitle: PropTypes.string,
  content: PropTypes.string,
};

Header.defaultProps = {
  children: (
    <>
      <h1>Better Pride</h1>
      <p>
        Better Pride is a an LGBTIQ+ support and well-being service with a focus
        on supporting LGBTIQ+ individuals, couples and families with
        relationship, parenting and personal issues.
      </p>
    </>
  ),
  siteTitle: 'Better Pride',
  title: '',
  content:
    'Better Pride is a an LGBTIQ+ support and well-being service with a focus on supporting LGBTIQ+ individuals, couples and families with relationship, parenting and personal issues.',
};

export default Header;
