import React, { Component } from 'react';

import "../styles/main.scss";
import { Seo } from '@better-place/better-ui';

import Head from "../components/Header";
import Banner from "../components/Banner";
import RightColoumn from "../components/RightColumn";
import Footer from "../components/Footer";

export default class Psychology extends Component {

render() {
  return (
    <div>
      <Seo siteTitle="Psychology" title="Better Pride" description="Working with a Better Pride practitioner can enable you to deal with mental health challenges more easily and constructively." />
      <Head />
      <Banner />
      <section className="main">
        <div className="container">
          <div className="row">
            <div className="col-sm-12 col-md-4 d-sm-block d-md-none">
              <RightColoumn />
            </div>
            <div className="col-sm-12 col-md-8">
              <div className="page-content">
                <h2 className="page-title">Why consider seeing a psychologist?</h2>
                <p>
                  Most people will experience difficulties and setbacks at some
                  point in their lives. Working with a Better Pride practitioner
                  can enable you to deal with mental health challenges more
                  easily and constructively. You can expect to gain a greater
                  understanding of yourself, your behaviours and thinking
                  patterns, and to enhance your sense of wellbeing and life
                  satisfaction.
                </p>

                <h2>Who uses a Psychologist?</h2>

                <p>
                  There are many reasons why you might see a psychologist. You
                  could be experiencing anxiety, depression, stressful life
                  events or any other mental health difficulty. You might not
                  even know what’s going on, but just feel that you haven’t been
                  ‘right’ or that your life has become more difficult. A
                  psychologist is trained to perform a detailed assessment of
                  your mental health and to identify what’s going on for you and
                  create a plan to support you through your difficult time.
                </p>

                <p>Our clients see our psychologists for issues such as:</p>

                <ul>
                  <li>Life changes and challenges</li>
                  <li>Anxiety and depression</li>
                  <li>Bullying</li>
                  <li>Isolation</li>
                  <li>Self-esteem &amp; self confidence</li>
                  <li>Estrangement from loved ones</li>
                  <li>Trauma from past violence.</li>
                </ul>

                <h2>What does a psychologist do?</h2>

                <p>
                  Through regular meetings the psychologist explores with you
                  the difficulties and issues you are facing. The psychologist
                  is trained in helping you apply new techniques, build
                  resilience and tackle negative thoughts, this helps you to see
                  things more clearly and from a different perspective. The
                  objective is to help you make a positive change.
                </p>

                <h2>What can you expect?</h2>

                <p>
                  Psychological services are confidential. Your Better Pride
                  clinician will complete an assessment of your mental health by
                  asking you a series of questions about what’s happening in
                  your life, as well as asking for details about your
                  background, relationships and personal history. The assessment
                  is an important first step, as it will help your psychologist
                  understand what’s troubling you and, more importantly, how to
                  help you. They’ll discuss with you a plan for continuing,
                  including how often they recommend you visit them and for how
                  long.
                </p>
              </div>
            </div>
            <div className="col-sm-12 col-md-4 d-none d-sm-none d-md-block">
              <RightColoumn />
            </div>
          </div>
        </div>
      </section>
      <Footer/>
    </div>
  );
}
}