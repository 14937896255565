import React, { Component } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhoneAlt } from "@fortawesome/free-solid-svg-icons";

import "../styles/main.scss";

import logo from "../images/logos/logo-b.png";

export class Banner extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      to_name: "",
      phone: "",
      ser: "",
      when: "",
      isSent: false,
    };
  }

  mySubmitHandler = (event) => {
    event.preventDefault();
    this.refs.btn.setAttribute("disabled", "disabled");
    this.refs.btn.setAttribute("value", "Sending...");

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");


    var email_body  = "<p>Name:   "+this.state.to_name+"</p>";
    email_body += '<p>Phone:  '+this.state.phone+"</p>";
    email_body += '<p>Reason: '+this.state.ser+"</p>";
    email_body += '<p>When:  '+this.state.when+"</p>";

    var raw = JSON.stringify({
      "host":"betterplaceaustralia.com.au",
      "port":465,
      "user":"bm9yZXBseUBiZXR0ZXJwbGFjZWF1c3RyYWxpYS5jb20uYXU=",
      "pass":"akpxZjJ7UCVpSEZI",
      "from":"Website Inquiry<intake@betterplaceaustralia.com.au>",
      "to":"Intake Team<intake@betterplaceaustralia.com.au>",
      "subject":"Callback request from Better Pride Website",
      "html":email_body
    });
    
    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };
    let dataLayer = window.dataLayer = window.dataLayer || [];
    fetch("https://us-central1-runway-api.cloudfunctions.net/SMTP_mail_sender", requestOptions)
      .then(response => {
        var result = response.status; 
        if(result == 204 ){
          this.refs.btn.setAttribute("class", "submit-button success");
          this.refs.btn.setAttribute("value", "Success");
          setTimeout(() => {
            this.setState({
              isSent: true,
            });
          }, 1500);
      
       window.dataLayer.push({event: 'RegisterInterestSubmitted',page: {
        Name : this.state.to_name,
        Phone : this.state.phone,
        Reason : this.state.ser,
        When : this.state.when
      }
       });
       dataLayer.push() ;
        }else{
          this.refs.btn.setAttribute("value", "Failed");
          this.refs.btn.setAttribute("class", "submit-button error");
          setTimeout(() => {
           this.resetForm();
           this.refs.btn.setAttribute("value", "Go");
          this.refs.btn.setAttribute("class", "submit-button");
        }, 1500);
        }
      
      })
      .catch(error => console.log('error', error));
  };

  myChangeHandler = (event) => {
    let nam = event.target.name;
    let val = event.target.value;
    this.setState({ [nam]: val });
  };

  resetForm = () => {
    this.setState({
      to_name: "",
      phone: "",
      ser: "",
      when: "",
    });
  };

  render() {
    const validForm = this.state.to_name && this.state.phone;
    let FormElement;
    let FormStyle;
    const isSent = this.state.isSent;
    if (isSent) {
      FormElement = (
        <p className="form-sent-text">Thank you! We'll be in touch soon.</p>
      );
      FormStyle = {
        minHeight: 115,
      };
    } else {
      FormElement = (
        <form onSubmit={this.mySubmitHandler}>
          <p>Or request a call back</p>
          <input
            type="text"
            placeholder="Name*"
            value={this.state.to_name}
            name="to_name"
            onChange={this.myChangeHandler.bind(this)}
          />
          <input
            type="text"
            placeholder="Phone*"
            name="phone"
            value={this.state.phone}
            onChange={this.myChangeHandler.bind(this)}
          />
          <select
            name="ser"
            id=""
            value={this.state.ser}
            onChange={this.myChangeHandler.bind(this)}
          >
            <option value="-1" defaultValue>
              Service
            </option>
            <option value="Mediation">Mediation</option>
            <option value="Counselling">Counselling</option>
            <option value="Psychology">Psychology</option>
          </select>
          <input
            type="text"
            name="when"
            placeholder="When should we call?"
            onChange={this.myChangeHandler}
            value={this.state.when}
          />
          {/* <button type="submit"> Go </button> */}
          <input
            disabled={!validForm}
            className="submit-button"
            ref="btn"
            onClick={this.onClickUploadFile}
            type="submit"
            value="Go"
          />
        </form>
      );
      FormStyle = {
        minHeight:  420
      }
    }
    return (
      <div className="right-column-content">
        <div className="card">
          <div className="card-img">
            <img src={logo} alt="" />
          </div>
          <h3>Better Pride</h3>
          <p>
            Better Place Australia is committed to providing a safe and
            high-quality service that is inclusive to all clients and respects
            the needs, wants and aspirations of everyone.
          </p>
        </div>
        <div className="card">
          <h2>Talk to us now</h2>
          <h2>
            <a
              className="d-none d-md-flex align-items-center justify-content-center"
              href="javascript:;"
            >
              <span className="icon">
                <FontAwesomeIcon icon={faPhoneAlt} />
              </span>{" "}
              1800 531 919
            </a>
            <a
              className="d-flex d-md-none align-items-center justify-content-center"
              href="tel:1800531919"
            >
              <span className="icon">
                <FontAwesomeIcon icon={faPhoneAlt} />
              </span>{" "}
              1800 531 919
            </a>
          </h2>
          <div className="form-section" style={FormStyle}>
            {FormElement}
          </div>
        </div>
      </div>
    );
  }
}

export default Banner;
