import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhoneAlt } from '@fortawesome/free-solid-svg-icons';
import { faFacebookF , faTwitter , faYoutube} from '@fortawesome/free-brands-svg-icons';
import '../styles/main.scss';

import flags from '../images/logos/flags.png';
import australia from '../images/logos/australia.png';
import relationships from '../images/logos/relationships.png';

export default class Footer extends Component {
render() {
    return (
    <div>
        <section className="blue-banner">
            <div className="container">
                <div className="row">
                    <div className="col-12 col-sm-12 col-md-8 col-lg-8">
                        <h3 className="text">Support is just a phone call away</h3>
                    </div>
                    <div className="col-12 col-sm-12 col-md-4 col-lg-4">
                            <h3 className="phone">
                                <a href="javascript:;" className="d-none d-md-flex align-items-center justify-content-center"> <span className="mr-2"><FontAwesomeIcon icon={faPhoneAlt} /></span> 1800 531 919</a>
                                <a href="tel:1800531919" className="d-flex d-md-none align-items-center justify-content-center"> <span className="mr-2"><FontAwesomeIcon icon={faPhoneAlt} /></span> 1800 531 919</a>
                            </h3>
                    </div>
                </div>
            </div>
        </section>

       <section className="upper-footer">
            <div className="container">
                <div className="row">
                    <div className="col-md-4">
                        <h3>Emergency assistance</h3>
                        <p>If you are in an emergency situation or need immediate assistance, please contact  emergency services on <a className="d-inline-block d-md-none" href="tel:000">000</a><a className="d-none d-md-inline-block" href="javascript:;">000</a> or Lifeline <a className="d-inline-block d-md-none" href="tel:131114">13 11 14.</a><a className="d-none d-md-inline-block" href="javascript:;">13 11 14.</a>  </p>
                    </div>
                    <div className="col-md-4">
                        <h3>Get help</h3>
                        <ul className="get-help-links">
                            <li><a href="http://www.betterplaceaustralia.com.au/contact-us/">Locations</a></li>
                            {/* <li><a href="javascript:;">Resources</a></li> */}
                            {/* <li><a href="javascript:;">Emergency and crisis</a></li> */}
                        </ul>
                    </div>
                    <div className="col-md-4">
                        <h3>Connect with us</h3>
                        <ul className="social-links">
                                <li><span className="social fb"><FontAwesomeIcon icon={faFacebookF} /></span> <a href="https://www.facebook.com/BetterPlaceAu/">Facebook</a></li>
                                <li><span className="social kuruwi"><FontAwesomeIcon icon={faTwitter} /></span> <a href="https://twitter.com/betterplaceau">Twitter</a></li>
                                <li><span className="social youtube"><FontAwesomeIcon icon={faYoutube} /></span><a href="https://www.youtube.com/user/FMCmediation/featured">Youtube</a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </section>

        <footer>
            <div className="container">
                <div className="row">
                    <div className="col-md-6">
                        <div className="footer-left">
                            <img src={flags} alt="" />
                                <p>Better Place Australia acknowledges the Traditional Custodians and their Elders in each of the communities where we work.</p>
                                <a href="http://www.betterplaceaustralia.com.au/privacy/" className="footer-link">Privacy policy</a>
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="footer-logo-wrapper">
                            <img src={australia} alt="" />
                            <a className="footer-link" href="http://www.betterplaceaustralia.com.au/">Visit Better Place Australia</a>
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="footer-logo-wrapper">
                            <img className="footer-logo" src={relationships} alt="" />
                            <a className="footer-link" href="https://centreforbetterrelationships.com.au/">Visit centre for better relationships</a>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
       </div>
    );
}
}
