import React from 'react';


import "../styles/main.scss";

import { Seo } from '@better-place/better-ui';
import Head from "../components/Header";
import Banner from "../components/Banner";
import RightColoumn from "../components/RightColumn";
import Footer from "../components/Footer";

import { Layout, ContentBlock, TextBlock } from '@better-place/better-ui';
import { Link } from 'gatsby';
import OurTeam from '../components/OurTeam';
import Test from '../components/Test';
import ContentHeader from '../components/ContentHeader';
import HomeImage from '../images/home.png';


const IndexPage = () => (
  <div>
    <Seo siteTitle="Support for LGBTIQ+ communities" title="Better Pride" description="Whether it’s in your relationship, coming out to others, changing your family situation or strengthening your mental health and wellbeing, Better Pride can help." />
    <Head/>
    <Banner />

    <section className="main">
      <div className="container">
        <div className="row">
            <div className="col-sm-12 col-md-4 d-sm-block d-md-none">
              <RightColoumn />
            </div>
            <div className="col-sm-12 col-md-8">
              <div className="page-content">
                <h2 className="page-title">welcome to better pride</h2>
                <p>
                  If you&#39;re here, you’re seeking something better. Whether
                  it’s in your relationship, coming out to others, changing your
                  family situation or strengthening your mental health and
                  wellbeing, Better Pride can help.
                </p>

                <h2>Who are we here for?</h2>

                <ul>
                  <li>
                    Families (biological, social and chosen) – either together
                    or separated/separating
                  </li>
                  <li>Couples (together or separating or reconciling)</li>
                  <li>Individuals</li>
                  <li>Young People (including children from ages 5&gt;)</li>
                  <li>
                    Elder/Senior people (individuals, couples, families –
                    biological and chosen)
                  </li>
                </ul>

                <h2>Better Pride LGBTIQ+ services</h2>

                <ul>
                  <li>
                    <Link to="/mediation">Mediation</Link>, including
                    Separation/Parenting/Care Agreements/Property/Finance
                  </li>
                  <li>Donor Planning</li>
                  <li>
                    <Link to="/counselling">Counselling</Link> &amp;{" "}
                    <Link to="/psychology">
                      psychological mental health support
                    </Link>
                  </li>
                  <li>Personal relationships</li>
                  <li>Intimate relationships / partnerships</li>
                  <li>Family relationships (both biological and chosen)</li>
                </ul>

                <h2>We can help with</h2>

                <ul>
                  <li>Life changes and challenges</li>
                  <li>Anxiety and depression</li>
                  <li>Bullying</li>
                  <li>Isolation</li>
                  <li>Estrangement from loved ones</li>
                  <li>
                    Trauma from past violence - intimate partner, family,
                    intercommunity violence and lateral violence.
                  </li>
                </ul>

                <h2>Better ways to separate- our mediation alternatives</h2>

                <p>
                  Better Pride Practitioners understand Family Law and work
                  alongside the legal system to provide a less stressful and
                  more cost effective way to navigate these already difficult
                  times.
                </p>

                <p>
                  The Better Pride service is provided by Accredited Family
                  Dispute Resolution practitioners experienced in separation and
                  family law issues. They can support you with:
                </p>

                <ul>
                  <li>Parenting plans and arrangements</li>
                  <li>
                    Post separation parenting decisions, including supporting
                    rainbow children
                  </li>
                  <li>Mediating agreements about finances and property</li>
                  <li>Decisions involving guild families/Co parents/ carers</li>
                  <li>Grandparent arrangements</li>
                  <li>Arrangements for pets</li>
                </ul>

                <p>
                  Better Pride also offers other forms of mediation, such as:
                </p>

                <ul>
                  <li>Parent and adolescent mediation</li>
                  <li>Donor planning</li>
                  <li>Guild families/Co parents/ carers</li>
                </ul>

                <h2>Better Support for counselling and mental health</h2>

                <p>
                  We can provide a range of therapeutic supports for couples,
                  individuals, older people, families, children and young
                  people.
                </p>

                <p>
                  Our Practitioners include case workers, social workers, family
                  dispute resolution practitioners, counsellors and
                  psychologists.
                </p>
              </div>
            </div>
            <div className="col-sm-12 col-md-4 d-none d-sm-none d-md-block">
              <RightColoumn />
            </div>
          </div>
      </div>
    </section>
    <Footer/>
  </div>
  
);

export default IndexPage;


