import React, { Component } from 'react';

import '../styles/main.scss';
import { useLocation } from "@reach/router"
import { Link } from 'gatsby';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhoneAlt, faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import ReactBootstrap, {
  Navbar,
  Nav,
  OverlayTrigger,
  Popover,
} from 'react-bootstrap';
import logo from '../images/logos/logo-b.png';
import LGBT_Rainbow_Flag from '../images/logos/LGBT_Rainbow_Flag.png';
import house from '../images/logos/house.png';

export default class header extends Component {
  state = {
    url:null,
    isLoaded: false
}

    componentDidMount() {
        this.setState({
          url:location.href,
          isLoaded: true
    })
}

  render(){
     const { url , isLoaded } = this.state;
     var prm = '';
     if (isLoaded) {
      var urlParams = url.split('=');
      prm = urlParams[1];
      var arr = url.split('/');
      var parameter = arr[arr.length - 1].split('?');
      var page = parameter[0];
      var cls = null;
      
      if(url.includes('mediation')){
        cls = "mediation";
      }else if (url.includes('counselling')){
        cls = "counselling";
      } else if (url.includes('psychology')){
        cls = "psychology";
      } else if (url.includes('about')){
        cls = "about";
      }else{
        cls = 'home';
      }
    }

     const popover = (
        <Popover id="popover-basic">
      <Popover.Title as="h3">What's this?</Popover.Title>
      <Popover.Content>
        Clicking on the Quick Exit button will bring up
        {' '}
        <strong> google news</strong>
        {' '}
        page so
        it is not immediately noticeable that you have been visiting our site,
        but it will not clear the Better Pride website from the browser history
        or back button
      </Popover.Content>
    </Popover>
  );
  
  
  return (
    <header>
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="top-bar d-none d-sm-flex">
              <div className="logo ">
                <Link to={(prm != null) ? (`/?prm=${prm}`) : '/'}>
                  <img src={logo} alt="Logo" />
                </Link>
              </div>
              <div className="details-wrapper">
                <div className="phone-number">
                  <a className="d-none d-md-block" href="javascript:;">
                    <span className="icon">
                      <FontAwesomeIcon icon={faPhoneAlt} />
                    </span>
                    {' '}
                    1800 531 919
                  </a>
                  <a className="d-block d-md-none" href="tel:1800531919">
                    <span className="icon">
                      <FontAwesomeIcon icon={faPhoneAlt} />
                    </span>
                    {' '}
                    1800 531 919
                  </a>
                </div>
                <div className="flag">
                  <img src={LGBT_Rainbow_Flag} alt="Logo" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <div className="mobile-menu d-block d-sm-none">
              <Navbar bg="light" expand="lg" fixed="top">
                <Navbar.Brand href={(prm != null) ? (`/?prm=${prm}`) : '/'}>
                  <img src={logo} alt="Logo" />
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                  <Nav className="mr-auto mt-2">
                    <Nav.Link
                      href={(prm != null) ? (`/?prm=${prm}`) : '/'}
                      >
                      Home
                    </Nav.Link>
                    <Nav.Link
                      href={(prm != null) ? (`/mediation/?prm=${prm}`) : '/mediation'}
                      >
                      Mediation
                    </Nav.Link>
                    <Nav.Link
                      href={(prm != null) ? (`/counselling/?prm=${prm}`) : '/counselling'}
                      >
                      Counselling
                    </Nav.Link>
                    <Nav.Link
                      href={(prm != null) ? (`/psychology/?prm=${prm}`) : '/psychology'}
                      >
                      Psychology
                    </Nav.Link>
                    <Nav.Link
                      href={(prm != null) ? (`/about/?prm=${prm}`) : '/about'}
                    >
                      About
                    </Nav.Link>
                  </Nav>
                </Navbar.Collapse>
              </Navbar>
            </div>
            <div className="menu-bar d-none d-sm-flex">
              <div
                className='menu-item first'
                >
                 <Link className={(cls != null && cls == 'home')?('active'):('')} to={(prm) ? (`/?prm=${prm}`) : '/'}>
                  <img src={house} alt="Logo" />
                </Link>
              </div>
              <div
                className='menu-item'
                >
                <Link className={(cls != null && cls == 'mediation')?('active'):('')} to={(prm != null) ? (`/mediation/?prm=${prm}`) : '/mediation'}>Mediation</Link>
              </div>
              <div
                className='menu-item'
                >
                <Link className={(cls != null && cls == 'counselling')?('active'):('')} to={(prm != null) ? (`/counselling/?prm=${prm}`) : '/counselling'}>Counselling</Link>
              </div>
              <div
                className='menu-item'
                >
                <Link className={(cls != null && cls == 'psychology')?('active'):('')} to={(prm != null) ? (`/psychology/?prm=${prm}`) : '/psychology'}>Psychology</Link>
              </div>
              <div
                className='menu-item'
                >
                <Link className={(cls != null && cls == 'about')?('active'):('')} to={(prm != null) ? (`/about/?prm=${prm}`) : '/about'}>About</Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <OverlayTrigger
        placement="left"
        overlay={popover}
        // trigger="hover"
        className=""
        >
        <a href="https://news.google.com/topstories" className="exit d-none d-sm-block">
          Quick exit
        </a>
      </OverlayTrigger>
      <div className="exit d-block d-sm-none d-flex justify-content-center align-items-center">
        <a href="https://news.google.com/topstories" className="mr-3">
          Quick exit
        </a>
        <OverlayTrigger
          placement="top"
          overlay={popover}
          trigger="click"
          className=""
          >
          <FontAwesomeIcon icon={faInfoCircle} />
        </OverlayTrigger>
      </div>
    </header>
  );
}
}
