import React, { Component } from 'react';
import "../styles/main.scss";

export default class NoContent extends Component {
render() {
  return (
    <div className="App">
      <div className="text-center my-5 mt-sm-0">
        <h1 className="my-3 mt-5">404 Page Not Found</h1>
        <p>The page you were looking for could not be found.</p>
      </div>
    </div>
  );
}
}