import React, { Component } from 'react';
import "../styles/main.scss";

import { Seo } from '@better-place/better-ui';

import Head from "../components/Header";
import Banner from "../components/Banner";
import RightColoumn from "../components/RightColumn";
import Footer from "../components/Footer";

export default class Counselling extends Component {

render() {
  return (
    <div>
      <Seo siteTitle="Counselling" title="Better Pride" description="Our counsellors can provide you with an opportunity to unburden yourself, process and begin feeling better." />
      <Head />
      <Banner />
      <section className="main">
        <div className="container">
          <div className="row">
            <div className="col-sm-12 col-md-4 d-sm-block d-md-none">
              <RightColoumn />
            </div>
            <div className="col-sm-12 col-md-8">
              <div className="page-content">
                <h2 className="page-title">Why do counselling?</h2>
                <p>
                  Life can be overwhelming and complicated. Our counsellors can
                  provide you with an opportunity to unburden yourself, process
                  and begin feeling better.
                </p>

                <p>
                  We work collaboratively with our clients, providing them with
                  an opportunity to reflect upon where they are and to work
                  towards positive change.
                </p>

                <h2>Who uses Counselling?</h2>

                <p>We can provide counselling for:</p>

                <ul>
                  <li>Individuals</li>
                  <li>
                    Couples that are together, separating or planning on
                    reconciling
                  </li>
                  <li>
                    Families (biological, social and chosen) – together or
                    separated/separating
                  </li>
                  <li>Young People (including children from ages 5 upwards)</li>
                  <li>
                    Elder/Senior people (individuals, couples, families –
                    biological and chosen).
                  </li>
                </ul>

                <p>
                  Our counselling clients come to us with a range of issues such
                  as:
                </p>

                <ul>
                  <li>Life changes and challenges</li>
                  <li>Relationship issues</li>
                  <li>Renewing relationships and reconnection with family</li>
                  <li>Family issues</li>
                  <li>Bullying</li>
                  <li>Isolation</li>
                  <li>Estrangement from loved ones</li>
                  <li>
                    Trauma from past violence - intimate partner, family,
                    intercommunity violence and lateral violence.
                  </li>
                </ul>

                <p>
                  Counselling can be a rewarding experience. It’s a process that
                  assists in stopping negative patterns and can promote your
                  strengths, your relationships and facilitate your desires,
                  needs and how to communicate them.
                </p>

                <h2>What does counselling do?</h2>

                <p>
                  Counselling responds to your need to change. It offers
                  professional support to work through your relationship issues,
                  parenting challenges, grief, blending of families or how to
                  work through day to day obstacles in your path.
                </p>

                <p>Our counsellors work with clients so they can:</p>

                <ul>
                  <li>Communicate more effectively</li>
                  <li>Deepen self-reflection</li>
                  <li>Understand needs and goals</li>
                  <li>Put the past in perspective</li>
                  <li>
                    Relate in healthier ways and develop positive relationships.
                  </li>
                </ul>

                <h2>What can you expect?</h2>

                <p>
                  Having an opportunity to attend a confidential session with
                  your counsellor will help you to address your issues in a
                  positive way. In your first session your counsellor will
                  discuss with you what your issues and concerns are. Your
                  counsellor will assist in your understanding of your situation
                  and through talking things through begin to establish how you
                  can approach your issues in a positive way.
                </p>

                <p>
                  Session numbers will vary by situation, your Better Pride
                  counsellor will discuss with you what you can expect in terms
                  of session numbers based on what you want to achieve.
                </p>
              </div>
            </div>
            <div className="col-sm-12 col-md-4 d-none d-sm-none d-md-block">
              <RightColoumn />
            </div>
          </div>
        </div>
      </section>
      <Footer/>
    </div>
  );
}
}