import React, { Component } from 'react';
import { Link } from 'gatsby';
import { Carousel } from 'react-bootstrap';

import '../styles/main.scss';

import loader from '../images/logos/index.svg';
import data from '../config.json';

export default class Banner extends Component {
  state = {
    isLoaded: false,
  };

  async componentDidMount() {
    this.setState({
      isLoaded: true,
    });
  }

  render() {
    const { isLoaded } = this.state;

    if (isLoaded) {
      var urlParams = new URLSearchParams(window.location.search);
      var prm = urlParams.get('prm');

      var URL = window.location.href;
      var arr = URL.split('/');
      var param;
      if (arr.length > 4) {
        param = arr[3];
      } else {
        param = arr[arr.length - 1].split('?')[0];
      }

      var URL = window.location.href;
      var arr = URL.split('/');
      var param;
      if (arr.length > 4) {
        param = arr[3];
      } else {
        param = arr[arr.length - 1].split('?')[0];
      }
      var x = '';
      var y = '';
      var breadcrumb = null;
      // home page
      if (param === '') {
        x = data.home;
      } else if (param === 'mediation') {
        x = data.meditation;
      } else if (param === 'counselling') {
        x = data.counselling;
      } else if (param === 'psychology') {
        x = data.psychology;
      } else if (param === 'about') {
        x = data.about;
      }

      if (URL.includes('mediation')) {
        breadcrumb = 'Mediation';
      } else if (URL.includes('counselling')) {
        breadcrumb = 'Counselling';
      } else if (URL.includes('psychology')) {
        breadcrumb = 'Psychology';
      } else if (URL.includes('about')) {
        breadcrumb = 'About';
      } else {
        breadcrumb = null;
      }

      if (prm != undefined) {
        y = x[prm];
        if (y == undefined) {
          y = x.default;
        }
      } else {
        y = x.default;
      }

      var desktoptext = y.destopbannertext;
      var mobiletext = y.mobilebannertext;
      var banner = y.carousel;

      return (
        <div className="App-banner">
          <section className="hero-banner">
            <div className="container-fluid p-0">
              <div className="row no-gutters">
                <div className="col-12 col-sm-6 col-md-7 col-lg-7">
                  <div className="banner-left">
                    <div className="breadcrumb">
                      <Link to={prm != null ? `/?prm=${prm}` : '/'}>
                        Home /{' '}
                      </Link>
                      {breadcrumb ? (
                        <span className="breadcrumb-second">
                          {' '}
                          {breadcrumb} /
                        </span>
                      ) : (
                        ''
                      )}
                    </div>
                    <div className="banner-text">
                      {/* desktop */}
                      <h1
                        className="d-none d-sm-block"
                        dangerouslySetInnerHTML={{ __html: desktoptext }}
                      ></h1>
                      {/* desktop */}

                      {/* moblie */}
                      <h1
                        className="d-block d-sm-none"
                        dangerouslySetInnerHTML={{ __html: mobiletext }}
                      ></h1>
                      {/* moblie */}
                    </div>
                  </div>
                </div>
                <div className="col-12 col-sm-6 col-md-5 col-lg-5">
                  <div className="banner-right">
                    <Carousel interval={3000} className="banner-carousel">
                      {banner.map((value, index) => {
                        var img = require('../' + value);
                        return (
                          <Carousel.Item key={index} className="carousel-item">
                            <img
                              className="d-block w-100"
                              src={img}
                              alt={index}
                            />
                          </Carousel.Item>
                        );
                      })}
                    </Carousel>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      );
    } else {
      return (
        <div className="col-md-12 mt-5 mt-sm-0">
          <div className="container pt-5 text-center mt-5 mt-sm-0">
            <img src={loader} alt="boohoo" className="img-responsive" />
          </div>
        </div>
      );
    }
  }
}
