import React from 'react';

const OurTeam = () => (
  <>
    <h2>Our Better Pride Team</h2>

    <p>
      From your initial contact with us you can expect non-judgmental,
      informative service and genuine support in finding the right service for
      you. To use our service, you will first need to contact our Intake and
      Assessment team and let them know that you are interested in accessing
      Better Pride services.
    </p>

    <p>
      Better Pride Practitioners identify as members or allies of the LGBTIQ+
      communities. We offer a depth of experience working with children and
      young people as well as adults and families, including older people.
    </p>

    <p>
      Please call us on our dedicated Better Pride contact number on
      {' '}
      <a href="tel:1800531919">1800 531 919</a>
    </p>
  </>
);

export default OurTeam;
