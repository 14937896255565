import React, { Component } from 'react';
import "../styles/main.scss";
import { Seo } from '@better-place/better-ui';
import Head from "../components/Header";
import Banner from "../components/Banner";
import RightColoumn from "../components/RightColumn";
import Footer from "../components/Footer";

export default class Meditation extends Component {

render() {
  return (
    <div>    
      <Seo siteTitle="Mediation" title="Better Pride" description="Better Pride offers mediation services in separation and Family Law, as well as other forms of mediation, such as Parent and adolescent mediation, donor planning, guild families, co-parents and carers" />
      <Head />
      <Banner />
      <section className="main">
        <div className="container">
          <div className="row">
            <div className="col-sm-12 col-md-4 d-sm-block d-md-none">
              <RightColoumn />
            </div>
            <div className="col-sm-12 col-md-8">
              <div className="page-content">
                <h2 className="page-title">Better pride Mediation</h2>
                <p>
                  Mediation can help people resolve an issue or issues that are
                  causing distress between them. Mediation is a supported
                  negotiation process that many people in conflict, separated
                  couples and/or families find useful, especially when
                  communication has broken down.
                </p>

                <p>
                  The Mediator is an impartial, skilled, professional third
                  party who helps those in conflict work through their issues
                  and guides them in addressing their conflict.
                </p>

                <p>
                  Mediators support people in conflict to have the conversations
                  they need to have, gain clarity about each person’s
                  perspective, explore their options and make decisions.
                  Mediation is confidential and designed to provide better
                  outcomes for all concerned.
                </p>

                <h2>
                  Mediation is less costly, generally quicker and less
                  emotionally draining than going to courts of law.
                </h2>

                <p>
                  Better Pride offers mediation services in separation and
                  Family Law, as well as other forms of mediation, such as:
                </p>

                <ul>
                  <li>Parent and adolescent mediation</li>
                  <li>Donor planning</li>
                  <li>Guild families, co-parents and carers</li>
                </ul>

                <h2>Better ways to separate - our mediation process</h2>

                <p>
                  Australia’s laws in relation to LGBTIQ+ relationships have
                  developed in recent decades, culminating in the legalisation
                  of same-sex marriage on 9 December 2017. As a result, LGBTIQ+
                  families are now recognised equally under the Family Law Act.
                </p>

                <p>
                  LGBTIQ+ families encounter many of the same issues as
                  heterosexual couples – and additional unique ones. Our
                  practitioners possess training, knowledge and experience in
                  supporting people with many different family structures. We
                  also acknowledge intersectionality – the ways in which
                  people’s different circumstances need to be taken into account
                  when deciding how to move forward.
                </p>

                <h2>Why do Family Dispute Resolution?</h2>

                <p>
                  The process of mediation in the Family Law and separation
                  context is known as Family Dispute Resolution. We support
                  families impacted by separation to communicate and make
                  decisions on issues relating to children, family, property
                  division, and financial matters.
                </p>

                <p>
                  The Better Pride Family Dispute Resolution service can support
                  you with:
                </p>

                <ul>
                  <li>
                    Post separation parenting decisions, including supporting
                    rainbow children
                  </li>
                  <li>Parenting plans and arrangements</li>
                  <li>
                    Mediating agreements about finances and property decisions
                    involving guild families, co-parents, carers
                  </li>
                  <li>Grandparent arrangements</li>
                  <li>Arrangements for pets.</li>
                </ul>

                <h2>Who uses Family Dispute Resolution?</h2>

                <p>
                  Parents, carers and families who are looking for a practical,
                  child-focused approach to resolving disputes and conflicts
                  that they are unable to settle on their own.
                </p>

                <h2>What does Family Dispute Resolution do?</h2>

                <p>
                  Separation can be a difficult time for all involved, at Better
                  Place Australia we understand this. Family Dispute Resolution
                  can help people to:
                </p>

                <ul>
                  <li>Determine parenting and child arrangements </li>
                  <li>Divide property and financial matters </li>
                  <li>Determine responsibilities and actions</li>
                  <li>Cut through complex issues appropriately</li>
                  <li>Negotiate mutually acceptable outcomes</li>
                  <li>Address communication issues</li>
                  <li>
                    Reduce legal and emotional costs, not to mention time.
                  </li>
                </ul>

                <h2>What can you expect?</h2>

                <p>
                  FDR practitioners will assess the appropriateness of mediation
                  in your case. They will consider issues surrounding family
                  conflict, equality of bargaining power, safety of children,
                  physical and mental wellbeing of parties and other relevant
                  matters.
                </p>

                <p>
                  One of our FDR practitioners will sit down separately with you
                  and:
                </p>

                <ul>
                  <li>
                    Listen to your side of the story and help clarify your
                    concerns
                  </li>
                  <li>
                    Assess whether FDR is the right process for your specific
                    situation
                  </li>
                  <li>
                    Give you information on the process and help you prepare for
                    FDR
                  </li>
                  <li>
                    Suggest appropriate referrals for legal advice, counselling,
                    income support or other assistance if required.
                  </li>
                </ul>

                <p>
                  The Practitioner will invite the other person to participate
                  and go through the same process. At the conclusion of these
                  sessions , the mediator will determine whether it is useful,
                  safe and appropriate for mediation to go ahead, or if another
                  service would be more suitable. Mediation can cater to
                  individual family circumstances and may be in held in the same
                  room or separate rooms.
                </p>

                <p>
                  When separated couples or families are in dispute about
                  children, the Commonwealth Family Law Act 1975 generally
                  requires both parties to attend Family Dispute Resolution
                  (FDR) to make a ‘genuine effort’ to resolve their issues
                  before they can a make a court application. If a matter is
                  assessed as inappropriate for mediation, a s60i certificate
                  can be issued. This certificate demonstrates that Family
                  Dispute Resolution has been attempted and can be used to
                  access the court for a judge to make a decision concerning a
                  child or children.
                </p>
              </div>
            </div>
            <div className="col-sm-12 col-md-4 d-none d-sm-none d-md-block">
              <RightColoumn />
            </div>
          </div>
        </div>
      </section>
      <Footer/>
    </div>
  );
}
}