import React, { Component } from 'react';
import "../styles/main.scss";
import { Seo } from '@better-place/better-ui';

import Head from "../components/Header";
import Banner from "../components/Banner";
import RightColoumn from "../components/RightColumn";
import Footer from "../components/Footer";

export default class About extends Component {
render() {
    return (
        <div>
            <Seo siteTitle="About" title="Better Pride" description="Our Better Pride service supports LGBTIQ+ people through facilitating community access, empowering clients, and working to diminish the impact of systemic challenges." />
            <Head/>
            <Banner />
            <section className="main">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-12 col-md-4 d-sm-block d-md-none">
                            <RightColoumn />
                        </div>
                        <div className="col-sm-12 col-md-8">
                            <div className="page-content">
                                <h2 className="page-title">About Better Pride</h2>
                                <p className="about-margin">
                                    At Better Place Australia, all people matter to us. We believe that everyone, regardless of background, has the right to be treated with respect, dignity, professionalism, promptness and care – this is why we introduced the Better Pride service stream. 
                                </p>
                                <p className="about-margin">
                                    We understand life can be distressing, emotional and confusing - but we're here to help you reach a better place.
                                </p>
                                <p>
                                    We believe that excellent support for LGBTIQ+ communities requires three key elements:
                                </p>
                                 <ol>
                                     <li>
                                        LGBTIQ+ identifying and ally Clinicians: All our Practitioners have worked with community members in a variety of capacities and understand the complex and relevant issues that can arise;
                                     </li>
                                     <li>
                                        Highly skilled and qualified Clinicians: All of our Better Pride Practitioners complete specialist training and education in the LGBTIQ+ space;
                                     </li>
                                     <li>
                                        A commitment to social justice and standing up and speaking out against discrimination:
                                     </li>
                                 </ol>
                                 <p>
                                    For some, the social climate makes it difficult to maintain an authentic sense of self: especially in the face of social expectations and pressures. Our Better Pride service supports LGBTIQ+ people through facilitating community access, empowering clients, and working to diminish the impact of systemic challenges.
                                 </p>
                            </div>
                        </div>
                        <div className="col-sm-12 col-md-4 d-none d-sm-none d-md-block">
                            <RightColoumn />
                        </div>
                    </div>
                </div>
            </section>
            <Footer/>
        </div>
    );
}
}